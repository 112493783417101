@import "../../node_modules/antd/dist/antd.less";

@subtleTextColor: #8c8c8c; //@gray-7;

// moved to the index.html
//body {
//    background-color: #f0f2f5;
//}
//
//#root {
//    height: calc(100vh - 55px);
//    overflow: auto;
//}

//AntDesign overrides -- Bad!!!!!
.ant-layout-header {
    background-color: unset;
    display: flex;
    align-items: center;
    line-height: unset;
    padding: 0;
}

.customBadgeStatusDots {
    & > .ant-badge-status-dot {
        width: 0.75em !important;
        height: 0.75em !important;
    }
}

//End of AntDesign overrides

.header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid @subtleTextColor;
    padding: 1em;
}

.headerRow {
    width: 100%;
}

.header_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer {
    background-color: #fff;
    display: flex;
    color: @subtleTextColor;
    line-height: 1;
    align-items: flex-end;
    flex-flow: row nowrap;
    justify-content: space-between;
    z-index: 100;
    width: 100%;
    height: 100%;
    border-top: 1px solid @subtleTextColor;
    padding: 1em;
    font-size: smaller;
}

.container {
    margin: 1em;
}

.noBottomBorderTableRow > td {
    border-bottom: 0 !important;
}

.basicIconClass {
    line-height: 1.5;
    font-size: 32px;
}

.warning {
    &:extend(.basicIconClass);
    color: @warning-color;
}

.error {
    &:extend(.basicIconClass);
    color: @error-color;
}

.schematronDetailsLabels {
    @media screen and (min-width: 768px) {
        text-align: end;
    }
    color: @subtleTextColor;
    padding-right: 1em;
}

.schematronDescription {
    padding-bottom: 1em;
    font-weight: 600;
}

.basicColumnClass {
    vertical-align: top;
}

.validationLineIconColumn {
    &:extend(.basicColumnClass);
    height: 1px; // this is the hack to fill dynamic the td height to 100%
}

.validationLineIconAndRuleColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    & > p {
        font-size: smaller;
        margin: 0;
    }
}

.validationLineDetailsColumn {
    &:extend(.basicColumnClass);

    > .schematronDetails {
        font-size: smaller;
    }
}

.validationLineGroupColumn {
    &:extend(.basicColumnClass);
}

.validationResultsTable {
    border: 1px solid #e8e8e8;
    margin: 1em 0;
}

.validText {
    color: @success-color;
    font-weight: 700;
    margin: 0;
}

.language_switcher_container {
    text-align: right;
    padding: 1em 1em 0 1em;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 2em !important;
}
.anticon-close {
    display: none !important;
}

.wrap_link_btn {
    text-align: start !important;
    white-space: pre-wrap !important;
    padding: 0 !important;
    font-size: unset !important;
    height: unset !important;
}
